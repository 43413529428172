import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth"
import React from "react"
// import * as authService from "src/services/auth"

interface SigninPayload {
  email: string
  password: string
}

const firebaseAuth = getAuth()
const authService = {
  signin: async (payload: SigninPayload) => {
    const { email, password } = payload
    await setPersistence(firebaseAuth, browserLocalPersistence)
    const cred = await signInWithEmailAndPassword(firebaseAuth, email, password)
    const user = cred.user
    return user
  },
  signout: async () => {
    await signOut(firebaseAuth)
  }
}

interface AuthContextType {
  session: any
  initialised: boolean
  signin: (payload: SigninPayload) => void
  signout: () => void
  error: any
}

let AuthContext = React.createContext<AuthContextType>(null!)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = React.useState<any>(null)
  const [error, setError] = React.useState<any>(null)
  const [initialised, setInitialised] = React.useState<boolean>(false)

  const handleError = (fn: (...args: any) => Promise<any>) => {
    return async (...args: any) => {
      try {
        await fn(...args)
        setError(null)
      } catch (err) {
        setError(err)
      }
    }
  }

  React.useMemo(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      console.log("onAuthStateChanged", user)
      setSession(user)
      setInitialised(true)
    })
  }, [])

  const signin = handleError(async (payload: SigninPayload) => {
    await authService.signin(payload)
  })

  const signout = handleError(async () => {
    await authService.signout()
  })

  let value = { session, initialised, signin, signout, error }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return React.useContext(AuthContext)
}
