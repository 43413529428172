import React from "react"
import { Navigate } from "react-router"

const NotFound: React.FC<{}> = (props) => {
  const { ...foo } = props

  return <Navigate to="/" />
}

export default NotFound
