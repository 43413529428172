import React from "react"
import { Navigate, Outlet, useLocation } from "react-router"
import { useAuth } from "src/context/auth"

const RequireAuth: React.FC<{}> = (props) => {
  const { ...foo } = props

  const location = useLocation()

  const { session, initialised } = useAuth()

  return initialised ? (
    session ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  ) : null
}

export default RequireAuth
