import React from "react"
import styled from "styled-components"

interface RadioProps {
  options: Array<{ label: string; value: string }>
  onChange: (value: string) => void
  value?: string
  name?: string
}

const Wrapper = styled.div``

const Option = styled.label`
  display: block;
  padding: 5px 0;

  & > span {
    margin-left: 5px;
  }
`

const Radio: React.FC<RadioProps> = (props) => {
  const { options, onChange, value, name } = props

  return (
    <Wrapper>
      {options.map((option) => {
        const { value: optionValue, label } = option
        const checked = optionValue === value
        return (
          <Option key={optionValue}>
            <input
              type="radio"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                onChange(optionValue)
              }}
              {...{ checked, name, value: optionValue }}
            />
            <span>{label}</span>
          </Option>
        )
      })}
    </Wrapper>
  )
}

export default Radio
