import { getFirestore } from "@firebase/firestore"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { initializeApp } from "firebase/app"

dayjs.extend(advancedFormat)

const firebaseConfig = {
  apiKey: "AIzaSyCCFwxjIQwgrrQeF7MBmzd0WTdmdvqfa6A",
  authDomain: "workout-4591a.firebaseapp.com",
  projectId: "workout-4591a",
  storageBucket: "workout-4591a.appspot.com",
  messagingSenderId: "743972328608",
  appId: "1:743972328608:web:7d8a97c0c3d833146a25c5"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore()

const firebase = { db, app }

const workoutNames = [
  "Arms & Shoulders",
  "Chest & Back",
  "Legs",
  "HIIT",
  "Run",
  "Other"
] as const

const colorMap: { [name: string]: string } = {
  "Arms & Shoulders": "#1f306e",
  "Chest & Back": "#553772",
  Legs: "#8f3b76",
  HIIT: "#c7417b",
  Run: "#f5487f",
  Other: "#ffa5d4"
}

const init = () => {}

const appConfig = { firebase, workoutNames, colorMap, init }

export default appConfig
