import dayjs, { Dayjs } from "dayjs"
import React from "react"
import colors from "src/style/colors"
import { normalizeButton } from "src/style/mixins"
import { formatDate } from "src/util/formatDate"
import styled from "styled-components"

const containerMaxWidth = 500

interface DayItem {
  x: number
  y: number
  date: Dayjs
}

const COLS = 7
const ROWS = 6

const Container = styled.div``

const SquaresContainer = styled.div`
  position: relative;
  padding-top: ${100 * (ROWS / COLS)}%;
`
const DaySquare = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${(p) => 100 * (p.x / COLS)}%;
  width: ${100 / COLS}%;
  top: ${(p) => 100 * (p.y / ROWS)}%;
  height: ${100 / ROWS}%;
  background-color: transparent;
`
const dayBorder = 5
const DayInner = styled.div<{ color?: string }>`
  position: absolute;
  top: ${dayBorder}%;
  right: ${dayBorder}%;
  bottom: ${dayBorder}%;
  left: ${dayBorder}%;
  background-color: ${(p) => p.color || "#eee"};
  border-radius: 5px;
`

const DayButton = styled.button.attrs({ type: "button" })`
  ${normalizeButton};
  display: block;
  width: 100%;
  height: 100%;
`

const DayText = styled.span`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.8);
  font-size: calc(0.05 * min(100vw, ${containerMaxWidth}px));
`

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const LabelText = styled.div`
  text-align: center;
  color: ${colors.lightText};
  text-transform: uppercase;
  font-size: 16px;
`

const DotwText = styled(LabelText)`
  width: ${100 * (1 / 7)}%;
  margin-bottom: 6px;
`

const MonthText = styled(LabelText)`
  width: 100%;
  letter-spacing: 0.2em;
  margin-bottom: 4px;
`

export type MonthCalendarData = { [date: string]: string }

const today = dayjs()

const MonthCalendar: React.FC<{
  date: Dayjs
  data?: MonthCalendarData
  onClickDate?: (date: string) => void
  isMini?: boolean
}> = (props) => {
  const { date, data, onClickDate, isMini } = props

  const content = React.useMemo(() => {
    const start = date.startOf("month")
    const end = date.endOf("month")
    const totalDays = end.diff(start, "day")
    const dayItems: DayItem[] = []
    for (let index = 0; index <= totalDays; index++) {
      const date = start.add(index, "days")
      const x = date.day()
      const y = Math.floor((date.get("date") - x - 1) / COLS) + 1
      dayItems.push({ date, x, y })
    }
    const dayEls = dayItems.map((dayItem) => {
      const { date, x, y } = dayItem
      const formattedDate = formatDate(date)

      const color =
        data?.[formattedDate] || (date.isAfter(today) ? "#f8f8f8" : "#e8e8e8")
      return (
        <DaySquare x={x} y={y} key={date.toISOString()}>
          <DayInner color={color}>
            {isMini ? null : (
              <DayButton
                onClick={
                  onClickDate ? () => onClickDate(formattedDate) : undefined
                }
              >
                <DayText children={date.get("date")} />
              </DayButton>
            )}
          </DayInner>
        </DaySquare>
      )
    })

    const labelEls = new Array(7).fill(0).map((_, index) => {
      const text = dayjs().day(index).format("dd").slice(0, 1)
      return <DotwText children={text} key={index} />
    })

    const monthText = isMini
      ? dayjs(date).format("MMM YY")
      : dayjs(date).format("MMMM YYYY")

    return (
      <>
        <LabelsContainer>
          <MonthText children={monthText} />
          {isMini ? null : labelEls}
        </LabelsContainer>
        <SquaresContainer>{dayEls}</SquaresContainer>
      </>
    )

    return (
      <>
        {dayEls}
        {labelEls}
      </>
    )
  }, [date, data])

  return <Container>{content}</Container>
}

export default MonthCalendar
