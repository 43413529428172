import dayjs, { Dayjs } from "dayjs"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from "firebase/firestore"
import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import appConfig from "src/appConfig"
import ColorKey from "src/components/ColorKey"
import MonthCalendar, { MonthCalendarData } from "src/components/MonthCalendar"
import Radio from "src/components/Radio"
import Spacer from "src/components/Spacer"
import { useAuth } from "src/context/auth"
import { normalizeButton } from "src/style/mixins"
import styled from "styled-components"

const containerMaxWidth = 500
const ContentContainer = styled.div`
  padding: 16px;
  width: 100%;
  max-width: ${containerMaxWidth}px;
  margin: 0 auto;
`
const WrapContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const MiniCalendarContainer = styled.button`
  ${normalizeButton};
  width: 50%;
  padding: 10px;
`

const { colorMap, workoutNames } = appConfig

const logCollection = collection(appConfig.firebase.db, "log")

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
`

const OverlayInner = styled.div`
  width: 100%;
  max-width: ${containerMaxWidth}px;
  margin: 0 auto;
  padding: 20px;
  height: 294px;
`

const radioOptions = [
  ...workoutNames.map((name) => ({
    label: name,
    value: name
  })),
  { label: "None", value: "" }
]

const RadioOverlay: React.FC<{ date?: string; onClose?: () => void }> = (
  props
) => {
  const { date, onClose } = props
  const [value, setValue] = React.useState<string>("")
  const uid = useAuth().session?.uid || "unknown"

  const queryClient = useQueryClient()

  const existingLogQuery = useQuery({
    queryKey: ["READ_LOGS", { userId: 1, date }],
    enabled: Boolean(date),
    queryFn: () =>
      getDocs(
        query(
          collection(appConfig.firebase.db, "log"),
          where("userId", "==", uid),
          where("date", "==", date)
        )
      )
  })

  React.useMemo(() => {
    const existingDoc = existingLogQuery.data?.docs[0]
    if (existingDoc && existingDoc.data().text) {
      setValue(existingDoc.data().text)
    } else {
      setValue("")
    }
  }, [existingLogQuery.data])

  const addLogMutation = useMutation({
    mutationFn: async () => {
      if (existingLogQuery.data) {
        if (existingLogQuery.data?.empty) {
          if (!value) return
          await addDoc(collection(appConfig.firebase.db, "log"), {
            date,
            text: value,
            userId: uid
          })
        } else {
          const existingDocId = existingLogQuery.data.docs[0].id
          const existingDoc = doc(appConfig.firebase.db, "log", existingDocId)
          if (value) {
            await updateDoc(existingDoc, {
              text: value
            })
          } else {
            await deleteDoc(existingDoc)
          }
        }
      } else {
        throw new Error("no data")
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("READ_LOGS")
    }
  })

  const handleSave = () => {
    addLogMutation.mutateAsync().then(() => {
      if (onClose) onClose()
    })
  }

  if (!date) return null

  return (
    <Overlay>
      <OverlayInner>
        {existingLogQuery.data ? (
          <>
            <div>{dayjs(date).format("ddd Do MMM")}</div>
            <Spacer height={10} />
            <Radio options={radioOptions} onChange={setValue} value={value} />
            <Spacer height={10} />
            <button type="button" children="Save" onClick={handleSave} />
          </>
        ) : null}
      </OverlayInner>
    </Overlay>
  )
}

const Main: React.FC<{}> = (props) => {
  const [start, setStart] = React.useState<Dayjs | null>(
    dayjs().startOf("month")
  )
  const [overlayDate, setOverlayDate] = React.useState<string>()
  const uid = useAuth().session?.uid || "unknown"

  const onClickDate = (date: string) => {
    setOverlayDate(date)
  }

  const readAllLogsQuery = useQuery({
    queryKey: ["READ_LOGS", { userId: 1 }],
    queryFn: () =>
      getDocs(
        query(
          collection(appConfig.firebase.db, "log"),
          where("userId", "==", uid)
        )
      )
  })

  const { monthCalendarData, totalMonths } = React.useMemo(() => {
    const monthCalendarData: MonthCalendarData = {}
    let earliestDate = ""
    if (readAllLogsQuery.data) {
      readAllLogsQuery.data.forEach((result) => {
        const resultData = result.data()
        const text = resultData?.text
        const date = resultData?.date
        if (text && date) {
          monthCalendarData[date] = colorMap[text as any] || "black"
          if (!earliestDate || date < earliestDate) earliestDate = date
        }
      })
    }
    const totalMonths =
      dayjs().diff(dayjs(earliestDate || undefined), "month") + 1
    return { monthCalendarData, totalMonths }
  }, [readAllLogsQuery.data])

  if (!readAllLogsQuery.data) return null

  return (
    <>
      {start ? (
        <ContentContainer>
          <MonthCalendar
            date={start}
            data={monthCalendarData}
            onClickDate={onClickDate}
          />
          <Spacer height={16} />
          <ColorKey />
          <Spacer height={16} />
          <button children="Zoom out" onClick={() => setStart(null)} />
        </ContentContainer>
      ) : (
        <WrapContainer>
          {new Array(totalMonths).fill(0).map((_, index) => {
            const start = dayjs().startOf("month").subtract(index, "month")

            return (
              <MiniCalendarContainer onClick={() => setStart(start)}>
                <MonthCalendar date={start} data={monthCalendarData} isMini />
              </MiniCalendarContainer>
            )
          })}
        </WrapContainer>
      )}
      <RadioOverlay
        date={overlayDate}
        onClose={() => setOverlayDate(undefined)}
      />
    </>
  )
}

export default Main
