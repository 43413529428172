import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import appConfig from "./appConfig"
import { AuthProvider } from "./context/auth"
import GlobalStyle from "./style/global"
import Login from "./views/Login"
import Main from "./views/Main"
import NotFound from "./views/NotFound"
import RequireAuth from "./views/RequireAuth"

const queryClient = new QueryClient()

appConfig.init()

const App: React.FC<{}> = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        {/* <Main /> */}
        <BrowserRouter>
          <Routes>
            <Route element={<Login />} path="/login" />
            <Route element={<RequireAuth />}>
              <Route element={<Main />} path="/" />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthProvider>
  )
}

export default App
