import React from "react"
import { Navigate, useLocation, useNavigate } from "react-router"
import { useAuth } from "src/context/auth"

const formConfig = [
  ["email", "Email"],
  ["password", "Password"]
] as const

const Login: React.FC<{}> = (props) => {
  const { ...foo } = props

  const navigate = useNavigate()
  const location = useLocation()
  const { signin, session, error } = useAuth()

  const from = (location.state as any)?.from?.pathname || "/"

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    let formData = new FormData(event.currentTarget)
    let email = formData.get("email") as string
    let password = formData.get("password") as string
    signin({ email, password })
  }

  return session ? (
    <Navigate to={from} replace />
  ) : (
    <form onSubmit={handleSubmit}>
      <input type="text" name="email" />
      <input type="password" name="password" />
      <button type="submit" children="Login" />
      <div children={error ? String(error) : null} />
    </form>
  )
}

export default Login
