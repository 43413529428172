import React from "react"
import appConfig from "src/appConfig"
import styled from "styled-components"
import Spacer from "./Spacer"

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin-bottom: 3px;
  margin-left: 10px;
`

const ColorSquare = styled.div<{ color: string }>`
  height: 0.7em;
  width: 0.7em;
  background-color: ${(p) => p.color};
  border-radius: 0.1em;
`

const KeyText = styled.div``

const ColorKey: React.FC<{}> = (props) => {
  const { ...foo } = props

  return (
    <div>
      {appConfig.workoutNames.map((name) => {
        return (
          <Row key={name}>
            <ColorSquare color={appConfig.colorMap[name]} />
            <Spacer width={8} />
            <KeyText children={name} />
          </Row>
        )
      })}
    </div>
  )
}

export default ColorKey
